<template>
  <v-app>
    <NavBar />
    <v-main style="background-color:#efefef; height: 100%;" class="ma-0 print">
      <v-container class="ma-0 pa-0" fluid style="height: 100%;">
        <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
          <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <PageFooter />
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import PageFooter from '@/components/PageFooter.vue';

export default {
    name: 'MainLayout',
    components: {
        NavBar,
        PageFooter,
    },
};
</script>

<style lang="css">
  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>
