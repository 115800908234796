import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser,
    faUserFriends as faUserFriendsSolid,
    faEnvelope, faPhone, faCheck, faFile,
    faCheckSquare, faPlus, faTrash, faSave, faCamera,
    faTimes, faSort, faSortDown, faCaretDown,
    faBriefcase, faCompress, faDesktop, faShieldAlt, faHeart, faUserSecret,
    faBuilding, faFingerprint, faCode, faBars, faUserCircle,
    faPencilAlt, faSearch, faEdit, faInfoCircle,
    faGlobe as faGlobeSolid,
    faCloudDownloadAlt, faUnlock,
    faCog as faCogSolid,
    faTag as faTagSolid,
    faEye as faEyeSolid,
    faChartLine as faChartLineSolid,
    faFolder as faFolderSolid,
    faFolderOpen as faFolderOpenSolid,
    faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
    faMinusSquare, faPaperclip, faArrowLeft,
    faEllipsisV as faEllipsisVSolid,
    faCloudUploadAlt as faCloudUploadAltSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faQuestion as faQuestionSolid,
    faIdBadge as faIdBadgeSolid,
    faChevronLeft as faChevronLeftSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare,
    faCircle, faDotCircle,
} from '@fortawesome/free-regular-svg-icons';
// import {
//     faWordpress as faWordpressBrand,
// } from '@fortawesome/free-brands-svg-icons';
import {
    faEllipsisV as faEllipsisVRegular,
    faCode as farCode,
    faExternalLinkAlt as faExternalLinkAltRegular,
    faSyncAlt as faSyncAltRegular,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faQuestionSquare as faQuestionSquareSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { faLoginshield } from '@/sdk/faLoginshield';

function setup() {
    Vue.component('FontAwesomeIcon', FontAwesomeIcon);
    Vue.component('FontAwesomeLayers', FontAwesomeLayers);
    Vue.component('FontAwesomeLayersText', FontAwesomeLayersText);
    library.add(
        faUser,
        faUserFriendsSolid,
        faEnvelope,
        faPhone,
        faCheck,
        faFile,
        faCheckSquare,
        faSquare,
        faCircle,
        faDotCircle, // used by v-radio element
        faPlus,
        faTrash,
        faSave,
        faCamera,
        faBriefcase,
        faCompress,
        faDesktop,
        faShieldAlt,
        faTimes,
        faSort,
        faSortDown,
        faCaretDown,
        faHeart,
        faBuilding,
        faUserSecret,
        faFingerprint,
        faCode,
        faBars,
        faUserCircle,
        faPencilAlt,
        faSearch,
        faEdit,
        faInfoCircle,
        faGlobeSolid,
        faCloudDownloadAlt,
        faUnlock,
        faCogSolid,
        faTagSolid,
        faEyeSolid,
        faChartLineSolid,
        faFolderSolid,
        faFolderOpenSolid,
        faAngleRight,
        faAngleLeft,
        faAngleDoubleRight,
        faAngleDoubleLeft,
        faMinusSquare,
        faPaperclip,
        faArrowLeft,
        faEllipsisVSolid,
        faCloudUploadAltSolid,
        faExclamationTriangleSolid,
        faQuestionCircleSolid,
        faQuestionSolid,
        faIdBadgeSolid,
        faChevronLeftSolid,
        faEllipsisVRegular,
        farCode,
        faExternalLinkAltRegular,
        faSyncAltRegular,
        faQuestionSquareSolid,
        faLoginshield,
    );
}

function mapFontAwesomeIcons(iconMap) {
    const output = {};
    Object.entries(iconMap).forEach(([key, value]) => {
        output[key] = {
            component: FontAwesomeIcon,
            props: {
                icon: value,
            },
        };
    });
    return output;
}

export default { mapFontAwesomeIcons, setup };
