<template>
  <v-row no-gutters justify="center" style="height: 100%" align="center">
    <v-col style="text-align: center" cols="12">
      <v-card elevation="2" class="my-6">
        <v-app-bar color="indigo" dark flat dense>
          <v-app-bar-title>Sign in to continue</v-app-bar-title>
        </v-app-bar>
        <v-card-text>
          <v-btn :to="loginRoute" elevation="2" color="indigo white--text" class="my-6" outlined>
            Sign in
          </v-btn>
          <p>
            No account yet? <router-link :to="signupRoute">
              Sign up
            </router-link>
          </p>
        </v-card-text>
      </v-card>
      <p class="mt-8">
        <a :href="mainWebsiteURL">Learn more about Xentri</a>
      </p>
    </v-col>
  </v-row>
</template>

<script>
export default {
    // ['nextRoute'], // caller can pass `this.$router.currentRoute.fullPath` as the value of this prop to return to same page after login
    props: {
        nextRoute: {
            type: [String, Object],
            default: null,
        },
    },
    computed: {
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://xentri.org';
        },
        loginRoute() {
            if (this.nextRoute) {
                return { name: 'login', query: { next: this.nextRoute } };
            }
            return { name: 'login' };
        },
        signupRoute() {
            return { name: 'signup' };
        },
    },
};
</script>
