import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/FrontPage.vue'),
        },
        {
            path: '/signup',
            name: 'signup',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/SignIn.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/ResumeInteraction.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileIndex.vue'),
        },
        {
            path: '/profile/authn',
            name: 'profile-settings-authn',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn" */ '../views/profile/ProfileAuthn.vue'),
        },
        {
            path: '/profile/session',
            name: 'profile-settings-session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-session" */ '../views/profile/ProfileSession.vue'),
        },
        {
            path: '/profile/setup',
            name: 'profile-setup',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "profile-setup" */ '../views/profile/ProfileSetup.vue'),
        },
        {
            path: '/profile/delete',
            name: 'profile-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-delete" */ '../views/profile/ProfileDelete.vue'),
        },
        {
            path: '/service',
            name: 'service-admin',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin" */ '../views/service/ServiceIndex.vue'),
        },
        {
            path: '/service/account-settings',
            name: 'service-admin-account-settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin-account-settings" */ '../views/service/AccountSettings.vue'),
        },
        {
            path: '/dashboard',
            name: 'user-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-dashboard" */ '../views/user/UserDashboard.vue'),
        },
        {
            path: '/create-account',
            name: 'user-account-create',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-account-create" */ '../views/user/CreateAccount.vue'),
        },
        {
            path: '/account/:accountId/setup',
            name: 'account-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-setup" */ '../views/account/AccountSetup.vue'),
        },
        {
            path: '/account/:accountId/delete',
            name: 'account-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete" */ '../views/account/AccountDelete.vue'),
        },
        {
            path: '/account',
            name: 'user-account-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-account-list" */ '../views/user/AccountList.vue'),
        },
        {
            path: '/account/:accountId',
            name: 'account-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-dashboard" */ '../views/account/AccountDashboard.vue'),
        },
        {
            path: '/account/:accountId/settings',
            name: 'account-settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-settings" */ '../views/account/AccountSettings.vue'),
        },
        // {
        //     path: '/account/:accountId/search/client',
        //     name: 'account-search-client',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "account-search-client" */ '../views/account/SearchClient.vue'),
        // },
        // {
        //     path: '/account/:accountId/search/client-token',
        //     name: 'account-search-client-token',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "account-search-client-token" */ '../views/account/SearchClientToken.vue'),
        // },
        // {
        //     path: '/account/:accountId/create/client-token',
        //     name: 'account-create-client-token',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "account-create-client-token" */ '../views/account/CreateClientToken.vue'),
        // },
        // {
        //     path: '/account/:accountId/edit/client',
        //     name: 'account-edit-client',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "account-edit-client" */ '../views/account/EditClient.vue'),
        // },
        // {
        //     path: '/account/:accountId/delete/client-token',
        //     name: 'account-delete-client-token',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "account-delete-client-token" */ '../views/account/DeleteClientToken.vue'),
        // },
        {
            path: '/account/:accountId/search/realm',
            name: 'account-search-realm',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-realm" */ '../views/account/SearchRealm.vue'),
        },
        {
            path: '/account/:accountId/create-realm',
            name: 'account-create-realm',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-realm" */ '../views/realm/RealmCreate.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId',
            name: 'realm-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-dashboard" */ '../views/realm/RealmDashboard.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/settings',
            name: 'realm-settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-settings" */ '../views/realm/RealmSettings.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/edit/brandprofile',
            name: 'realm-edit-brandprofile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-edit-brandprofile" */ '../views/realm/EditBrandProfile.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/loginshield',
            name: 'realm-loginshield',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-loginshield" */ '../views/realm/LoginShield.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/webauthz/grant',
            name: 'realm-webauthz-grant',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-webauthz-grant" */ '../views/realm/webauthz/WebauthzGrant.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/app-list',
            name: 'realm-app-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-app-list" */ '../views/realm/AppList.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/app/:appId',
            name: 'realm-app-view',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-app-view" */ '../views/realm/AppView.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/user-list',
            name: 'realm-user-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-user-list" */ '../views/realm/UserList.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/user/:userId',
            name: 'realm-user-view',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-user-view" */ '../views/realm/UserView.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/create/client-token',
            name: 'realm-create-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-create-client-token" */ '../views/realm/CreateClientToken.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/edit/client',
            name: 'realm-edit-client',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-edit-client" */ '../views/realm/EditClient.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/delete/client-token',
            name: 'realm-delete-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-delete-client-token" */ '../views/realm/DeleteClientToken.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/search/client',
            name: 'realm-search-client',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-search-client" */ '../views/realm/SearchClient.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/search/client-token',
            name: 'realm-search-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-search-client-token" */ '../views/realm/SearchClientToken.vue'),
        },
        {
            path: '/account/:accountId/realm/:realmId/delete',
            name: 'account-delete-realm',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete-realm" */ '../views/realm/RealmDelete.vue'),
        },
        {
            path: '/webauthz/prompt',
            name: 'webauthz-prompt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/WebauthzPrompt.vue'),
        },
        // {
        //     path: '/realm/:realm/contact',
        //     name: 'realm-contact',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "realm-contact" */ '../views/realm/RealmContact.vue'),
        // },
        // {
        //     path: '/realm/preferences',
        //     name: 'realm-preferences',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "realm-preferences" */ '../views/realm/RealmPreferences.vue'),
        // },
        // {
        //     path: '/realm/:realm/preferences/authn',
        //     name: 'realm-user-preferences-authn',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "realm-user-preferences-authn" */ '../views/realm/PreferencesAuthn.vue'),
        // },
        // {
        //     path: '/account',
        //     name: 'account',
        //     component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
        // },
        // {
        //     path: '/account/view',
        //     name: 'account-view',
        //     component: () => import(/* webpackChunkName: "account-view" */ '../views/AccountView.vue'),
        // },
        // {
        //     path: '/realm/:realm/admin/user/delete',
        //     name: 'realm-user-delete',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "realm-admin-user-delete" */ '../views/realm/UserDelete.vue'),
        // },
        // {
        //     path: '/group',
        //     name: 'group',
        //     component: () => import(/* webpackChunkName: "group" */ '../views/Group.vue'),
        // },
        // {
        //     path: '/group/view',
        //     name: 'group-view',
        //     component: () => import(/* webpackChunkName: "group-view" */ '../views/GroupView.vue'),
        // },
        // {
        //     path: '/access-policy',
        //     name: 'access-policy',
        //     component: () => import(/* webpackChunkName: "access-policy" */ '../views/AccessPolicy.vue'),
        // },
        // {
        //     path: '/access-policy/view',
        //     name: 'access-policy-view',
        //     component: () => import(/* webpackChunkName: "access-policy-view" */ '../views/AccessPolicyView.vue'),
        // },
        // {
        //     path: '/certificate-authority',
        //     name: 'certificate-authority',
        //     component: () => import(/* webpackChunkName: "certificate-authority" */ '../views/CertificateAuthority.vue'),
        // },
        // {
        //     path: '/certificate-authority/view',
        //     name: 'certificate-authority-view',
        //     component: () => import(/* webpackChunkName: "certificate-authority-view" */ '../views/CertificateAuthorityView.vue'),
        // },
        // {
        //     path: '/certificate',
        //     name: 'certificate',
        //     component: () => import(/* webpackChunkName: "certificate" */ '../views/Certificate.vue'),
        // },
        // {
        //     path: '/certificate/view',
        //     name: 'certificate-view',
        //     component: () => import(/* webpackChunkName: "certificate-view" */ '../views/CertificateView.vue'),
        // },
        // {
        //     path: '/create-account',
        //     name: 'create-account',
        //     component: () => import(/* webpackChunkName: "create-account" */ '../views/CreateAccount.vue'),
        // },
        // {
        //     path: '/create-account/conflict',
        //     name: 'create-account-conflict',
        //     component: () => import(/* webpackChunkName: "create-account-conflict" */ '../views/CreateAccountConflict.vue'),
        // },
        // {
        //     path: '/create-account/login',
        //     name: 'create-account-login',
        //     component: () => import(/* webpackChunkName: "create-account-login" */ '../views/CreateAccountLogin.vue'),
        // },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
